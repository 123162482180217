import fc from '../../media/fc.jpg'
import marron from '../../media/marron.jpg'
import ancelin from '../../media/ancelin.jpg'
import rubyk from '../../media/rubyk.jpg'
export default function FeaturedCollection(){
    return(
        <div className='mt-[50px]'>
            <div className='py-[12px] dilutedpink flex justify-center'>
                <img src={fc} className='w-[500px] h-[70px] -translate-x-[50px]' alt='Featured Collection'/>
            </div>
            <div className='mt-[50px] w-10/12 mx-auto flex gap-20'>
                <div className='flex flex-col w-[210px] items-center gap-2'>
                    <img src={rubyk} className='h-[270px]' alt='Ruby K'/>
                    <div className='dmsans text-[18px]'>RUBY K</div>
                    <div className='bg-[#EDB2C3] w-[50px] h-[1px]'></div>
                    <div className='dilutedpinktext font-bold text-[20px]'>£900</div>
                </div>
                <div className='flex flex-col w-[210px] items-center gap-2'>
                    <img src={ancelin} className='h-[270px]' alt='Ancelin'/>
                    <div className='dmsans text-[18px]'>Ancelin</div>
                    <div className='bg-[#EDB2C3] w-[50px] h-[1px]'></div>
                    <div className='dilutedpinktext font-bold text-[20px]'>£900</div>
                </div>
                <div className='flex flex-col w-[210px] items-center gap-2'>
                    <img src={marron} className='h-[270px] w-[200px]' alt='Marron'/>
                    <div className='dmsans text-[18px]'>Marron</div>
                    <div className='bg-[#EDB2C3] w-[50px] h-[1px]'></div>
                    <div className='dilutedpinktext font-bold text-[20px]'>£900</div>
                </div>
            </div>
        </div>
    )
}