import welcome from '../../media/welcomeimage.jpg'
export default function FirstSection(){
    return(
    <div className="bg-black mt-[150px] z-10">
            <div>
            <div className="text-white flex h-[100px] w-11/12 md:w-[730px] justify-center gap-3 md:justify-between items-center mx-auto flex-col md:flex-row">
                <div className="font-extrabold md:font-bold text-[17px] md:text-[24px] tracking-widest">NEED YOUR HAIR DONE?</div>
                <button className="bg-white text-black rounded-3xl h-[40px] py-[8px] px-[20px] md:px-[60px] roboto font-bold text-[14px] bg-white text-black p-3 hover:text-white border-2 hover:bg-black hover:border-white delay-75 transition ease-in-out duration-[350ms]">BOOK APPOINTMENT</button>
            </div>
            </div>
            <img className="w-full h-auto bg-gray-400 flex justify-center items-center text-[40px]" src={welcome} alt="Welcome"/>
        </div>
    )
}