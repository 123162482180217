import Navbar from "./Navbar"
import FirstSection from "./FirstSection"
import Collection from "./Collection"
import FeaturedCollection from "./FeaturedCollection"
import Contact from "./Contact"
import "../../App.css"
export default function Home(){
    return(
        <div>
            <Navbar/>
            <FirstSection/>
            <Collection/>
            <FeaturedCollection/>
            <Contact/>
        </div>
    )
}