import shc from '../../media/SHC.jpg'
import collection1 from '../../media/collection1.jpg'
import collection2 from '../../media/collection2.jpg'
import collection3 from '../../media/collection3.jpg'
import collection4 from '../../media/collection4.jpg'
import collection5 from '../../media/collection5.jpg'
import collection6 from '../../media/collection6.jpg'
import collection7 from '../../media/collection7.jpg'
import collection8 from '../../media/collection8.jpg'

export default function Collection(){
    return(
        <div>
            <div className='pt-[30px] flex justify-center w-full'>
                <img src={shc} className='w-[950px] h-[65px]'/>
            </div>
            <div className='grid grid-cols-4 mt-[25px]'>
                <img src={collection1} alt='Collection 1'/>
                <img src={collection2} alt="Collection 2"/>
                <img src={collection3} alt="Collection 3"/>
                <img src={collection7} alt="Collection 4"/>
                <img src={collection4} alt="Collection 5"/>
                <img src={collection5} alt="Collection 6"/>
                <img src={collection6} alt="Collection 7"/>
                <img src={collection8} alt="Collection 8"/>

            </div>
        </div>
    )
}