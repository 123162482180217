export default function Hamburger({ham, setHam}) {
  return (
    <div className="lg:hidden translate-y-2">
    {ham ? (
        <div className="flex flex-col gap-1" onClick={()=>setHam(false)}>
        <div className="h-[2px] w-[18px] bg-black div1 delay-30 transition ease-in-out duration-300"></div>
        <div className="h-[2px] w-[18px] bg-black div2 delay-30 transition ease-in-out duration-300"></div>
        <div className="h-[2px] w-[18px] bg-black div3 delay-30 transition ease-in-out duration-300"></div>
      </div>
    ):(
    <div className="flex flex-col gap-1 hamburger" onClick={()=>setHam(true)}>
        <div className="h-[2px] w-[18px] bg-black div1 delay-30 transition ease-in-out duration-300"></div>
        <div className="h-[2px] w-[18px] bg-black div2 delay-30 transition ease-in-out duration-300"></div>
        <div className="h-[2px] w-[18px] bg-black div3 delay-30 transition ease-in-out duration-300"></div>
      </div>
    )}

      

    </div>
  );
}
