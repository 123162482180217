import { CiUser } from "react-icons/ci";
import { FaHeart } from "react-icons/fa";
import { GiShoppingBag } from "react-icons/gi";
import Hamburger from "./Hamburger";
import { useState } from "react";
export default function Navbar(){
    const [ham, setHam] = useState(true)
    return(
        <div className="w-full fixed top-0 left-0 z-50 white">
            <div className="w-11/12 flex mx-auto justify-between h-[75px] items-center">
                <div className="playwrite-be-vlg-pink text-2xl text-bold">Pink Zee Aesthetics</div>
                <div className="flex w-[130px] justify-between items-center">
                    <CiUser size={30}/>
                    <FaHeart size={23} className="translate-y-[1px]"/>
                    <GiShoppingBag size={25}/>
                </div>
            </div>
            <div className="w-full dilutedpink">
                <div className="w-11/12 lg:w-10/12 flex flex-col lg:flex-row mx-auto justify-between items-center lg:-translate-x-[60px] h-auto lg:h-[75px] py-[20px] lg:py-0 dmsans md:text-[17px] gap-[20px]">
                    <Hamburger ham={ham} setHam={setHam}/>
                    <div className="lg:hidden">
                    {!ham &&
                    (                    
                    <div className="flex gap-[20px] flex-col items-center pt-[5px]"><div className='hover:text-pink-400 hover:scale-[1.12] delay-30 transition ease-in-out duration-100 cursor-default'>Home</div><div className='hover:text-pink-400 hover:scale-[1.12] delay-30 transition ease-in-out duration-100 cursor-default'>Shop</div><div className='hover:text-pink-400 hover:scale-[1.12] delay-30 transition ease-in-out duration-100 cursor-default'>Custom Units</div><div className='hover:text-pink-400 hover:scale-[1.12] delay-30 transition ease-in-out duration-100 cursor-default'>Book Appointment</div><div className='hover:text-pink-400 hover:scale-[1.12] delay-30 transition ease-in-out duration-100 cursor-default'>Gallery</div><div className='hover:text-pink-400 hover:scale-[1.12] delay-30 transition ease-in-out duration-100 cursor-default'>Follow Us</div><div className='hover:text-pink-400 hover:scale-[1.12] delay-30 transition ease-in-out duration-100 cursor-default'>Beauty Treatments</div></div>)
                    }
                    </div>

                    <div className="hidden lg:flex justify-between items-center w-11/12 mx-auto">    <div className='hover:text-pink-400 hover:scale-[1.12] delay-30 transition ease-in-out duration-100 cursor-default'>Home</div><div className='hover:text-pink-400 hover:scale-[1.12] delay-30 transition ease-in-out duration-100 cursor-default'>Shop</div><div className='hover:text-pink-400 hover:scale-[1.12] delay-30 transition ease-in-out duration-100 cursor-default'>Custom Units</div><div className='hover:text-pink-400 hover:scale-[1.12] delay-30 transition ease-in-out duration-100 cursor-default'>Book Appointment</div><div className='hover:text-pink-400 hover:scale-[1.12] delay-30 transition ease-in-out duration-100 cursor-default'>Gallery</div><div className='hover:text-pink-400 hover:scale-[1.12] delay-30 transition ease-in-out duration-100 cursor-default'>Follow Us</div><div className='hover:text-pink-400 hover:scale-[1.12] delay-30 transition ease-in-out duration-100 cursor-default'>Beauty Treatments</div>
                    </div>
                </div>                    
            </div>
        </div>
    )
}