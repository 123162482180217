import { FaLocationDot } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";

export default function Contact(){
    return (
        <div className="w-full ">
            <div className="dmsans text-[40px] flex justify-center mt-[20px] dilutedpinktext">Contact Us</div>
            <div className="w-11/12 mx-auto flex justify-center flex-col items-center gap-3 mt-[14px]">
            <div className="dmsans flex gap-2 text-[20px]">
                <FaLocationDot className="mt-[5px]"/>
                <div className="flex flex-col gap-1 lg:flex-row">
            <div>16 Maple drive</div>
            <div>Fobbing</div>
            <div>Stanford-le-Hope</div>
            <div>SS17 9JU</div>
            </div>
            </div>
            <div className="dmsans flex gap-2 items-center text-[20px]">
                <FaPhone/>
                <div>+44 7462 785266</div>
            </div>
            </div>
        </div>
    )
}